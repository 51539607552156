import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/react"

const NotFound = () => {
  return (
    <Layout title="404: Page not found">
      <div css={container} id="notfound">
        <h1>ページが見つかりませんでした</h1>
        <p>お探しのページは存在しないか、移動した可能性があります。</p>
      </div>
    </Layout>
  )
}
export default NotFound

const container = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  padding: 90px 0;

  @media (max-width: 767px) {
    padding: 54px 0 46px;
  }

  @media (max-width: 767px) {
    width: 94%;
  }
`
